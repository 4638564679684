<template>
  <base-modal :title="title" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label position="stacked">{{ label }}</ion-label>
          <ion-datetime
            required
            display-format="DD-MM-YYYY"
            :value="date"
            :min="$moment().format('YYYY-MM-DD')"
            :max="$moment().add('5', 'years').format('YYYY-MM-DD')"
            @ionChange="onDateChange($event)"
          />
        </ion-item>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-button @click="submit" expand="full" color="moto">{{ $t('ok') }}</ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'

export default {
  props: ['title', 'label', 'value', 'onSubmit'],
  components: {
    BaseModal,
  },
  data() {
    return {
      date: this.$moment(this.value),
    }
  },
  watch: {
    value(newValue) {
      this.date = this.$moment(newValue);
    }
  },
  methods: {
    submit() {
      this.onSubmit(this.date);
      this.$ionic.modalController.dismiss();
    },
    onDateChange($event) {
      this.date = this.$moment(new Date($event.target.value));
    }
  }
}
</script>