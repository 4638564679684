<template>
  <ion-list class="ion-padding">
    <ion-list-header>
      <ion-label class="font-weight-bold">{{ $t('bulkupdatemembersamount', {amount}) }}</ion-label>
    </ion-list-header>
    <ion-list-item>
      <ion-button size="full" @click="selectItem('cancelMembership')">{{ $t('cancelmembership')}}</ion-button>
    </ion-list-item>
    <ion-list-item>
      <ion-button size="full" @click="selectItem('changeExpirationDate')">{{ $t('changeexpirationdate')}}</ion-button>
    </ion-list-item>
    <ion-list-item>
      <ion-button size="full" @click="selectItem('changeSubscription')">{{ $t('changesubscription') }}</ion-button>
    </ion-list-item>
    <ion-list-item>
      <ion-button size="full" color="moto"  @click="selectItem()">{{ $t('cancel') }}</ion-button>
    </ion-list-item>
  </ion-list>
</template>
<script>
  export default {
    props:['amount', 'selectItem'],
  }
</script>