<template>
  <ion-page class="ion-page">
    <GoBackHeader :default-href="`/facilities/${id}`" />
    <ion-content>
      <Refresher @refresh="refresh($event)" />
      <ion-list>
        <ion-list-header>
          <ion-label>{{ $t('bulkeditmembers') }}</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>{{ $t('sortby') }}:</ion-label>
          <ion-button @click="openPopOverSortOrder" size="small">
            <ion-text>
              {{ selectedSortOrder.label }}
            </ion-text>
          </ion-button>
        </ion-item>
      </ion-list>
      <List
        :header="$t('members')"
        :not-found-message="$t('nomemberslabel')"
        :loading="$apollo.loading"
      >
        <template v-if="filteredMembers">
          <ion-item v-for="member in filteredMembers" :key="member.id" @click="toggleMember(member)">
            <ion-checkbox slot="start" :checked="isSelected(member)" color="moto" />
            <MemberItem :member="member" />
          </ion-item>
        </template>
      </List>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <Error :error="error" class="ion-padding"/>
        <ion-button color="moto" @click="showPopover" :disabled="bulkActionsDisabled" size="full">
          <ion-text>
            {{ $t('bulkupdatemembersamount', {amount: selectionCount}) }}
          </ion-text>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
  import GoBackHeader from '@/components/navigation/GoBackHeader';
  import List from '@/components/List';
  import { GET_FACILITY, GET_FACILITY_MEMBERS } from '../../graphql/queries';
  import MemberItem from "@/components/items/MemberItem.vue";
  import BulkUpdatePopover from "@/views/facilities/BulkUpdatePopover.vue";
  import { confirm, showToast } from "@/utils/helpers";
  import { CANCEL_MEMBERSHIPS, UPDATE_FACILITY_MEMBERS } from "@/graphql/mutations";
  import SelectSubscriptionModal from "@/components/modals/SelectSubscriptionModal.vue";
  import SelectDateModal from "@/components/modals/SelectDateModal.vue";
  import { sortBy } from "lodash";
  import PopOverMemberSortOrder from "@/components/popovers/PopOverMemberSortOrder.vue";

  export default {
    components: {
      MemberItem,
      GoBackHeader,
      List,
    },
    props: [
      'id'
    ],
    data() {
      const sortOrders = [
        {
          label: this.$t("sortbyname"),
          key: "NAME"
        },
        {
          label: this.$t("sortbyemail"),
          key: "EMAIL"
        },
        {
          label: this.$t("sortbysubscribedat"),
          key: "SUBSCRIBED_AT"
        },
        {
          label: this.$t("sortbyexpiresat"),
          key: "EXPIRES_AT"
        },
        {
          label: this.$t("sortbysubscription"),
          key: "SUBSCRIPTION"
        },
      ];

      return {
        sortOrders,
        selectedSortOrder: sortOrders[0],
        error: null,
        selected: [],
        categorySortOrderModal: null,
      }
    },
    computed: {
      bulkActionsDisabled() {
        return !this.selected.length;
      },
      selectionCount() {
        return this.selected.length;
      },
      filteredMembers() {
        return sortBy(this.getFacilityMembers, [(facilityMember) => {
          switch (this.selectedSortOrder.key) {
            case 'EMAIL':
              return facilityMember.user.email;
            case 'SUBSCRIBED_AT':
              return facilityMember.became_member_at;
            case 'EXPIRES_AT':
              return facilityMember.expires_at;
            case 'SUBSCRIPTION':
              return facilityMember.subscription.name;
            default:
              return facilityMember.user.last_name;
          }
        }]);
      }
    },
    beforeUnmount() {
      this.$ionic.modalController.dismiss();
    },
    methods: {
      showToast,
      confirm,
      async openPopOverSortOrder(event) {
        const p = await this.$ionic.popoverController.create({
          component: PopOverMemberSortOrder,
          componentProps: {
            parent: this,
            propsData: {
              activeItem: this.selectedSortOrder,
              items: this.sortOrders,
              selectItem: this.selectSortOrder,
              dismiss: this.dismissPopoverSortOrder
            }
          },
          translucent: false,
          event
        });

        this.categorySortOrderModal = p;

        await p.present();
      },
      selectSortOrder(sortOrder) {
        this.selectedSortOrder = sortOrder;
      },
      dismissPopoverSortOrder() {
        this.categorySortOrderModal?.dismiss();
      },
      refresh($event) {
        this.selected = [];
        const p = this.$apollo.queries.getFacilityMembers.refetch();

        // Refresher event handler
        if ($event) {
          p.then( () => $event.target.complete() );
        }
      },
      toggleMember(member) {
        const index = this.selected.indexOf(member.id);
        if (index === -1) {
          this.selected.push(member.id);
        } else {
          this.selected.splice(index, 1);
        }
      },
      firstSelectedMember() {
        const memberId = this.selected[0];
        if (!memberId) {
          return null;
        }
        return this.getFacilityMembers.find( (item) => item.id === memberId );
      },
      isSelected(member) {
        return this.selected.includes(member.id);
      },
      async showPopover() {
        const popOver = await this.$ionic.popoverController.create({
          component: BulkUpdatePopover,
          componentProps: {
            parent: this,
            propsData: {
              amount: this.selectionCount,
              selectItem: this.onPopoverAction
            }
          }
        })

        popOver.present();
      },
      onPopoverAction(action) {
        this.$ionic.popoverController.dismiss();

        switch (action) {
          case 'cancelMembership':
            this.bulkCancelMembership();
            break;
          case 'changeExpirationDate':
            this.bulkChangeExpirationDate();
            break;
          case 'changeSubscription':
            this.bulkChangeSubscription();
            break;
          default:
            break;
        }
      },
      async bulkCancelMembership() {
        const amount = this.selectionCount;
        const confirmed = await this.confirm(this.$t('cancelmemberships'), this.$t('areyousurethatyouwanttocancelmanymemberships', {amount}));

        if (!confirmed) {
          return;
        }

        try {
          await this.$apollo.mutate({
            mutation: CANCEL_MEMBERSHIPS,
            variables: {
              ids: this.selected,
              facility_id: this.id,
            }
          });
          this.showToast(this.$t('membershipscancelledamount', {amount}))
        } catch (e) {
          this.error = e;
          return;
        }

        this.refresh();
      },
      async bulkChangeExpirationDate() {
        const selectedMember = this.firstSelectedMember();

        const modal = await this.$ionic.modalController.create({
          component: SelectDateModal,
          componentProps: {
            parent: this,
            propsData: {
              title: this.$t('changeexpirationdate'),
              label: this.$t('expiresat'),
              value: this.$moment(selectedMember?.expires_at),
              onSubmit: (date) => {
                modal.dismiss();
                this.confirmBulkChangeExpirationDate(date);
              },
              subscriptions: this.facility.enabled_subscriptions
            }
          }
        });

        return modal.present();
      },
      async bulkChangeSubscription() {

        const modal = await this.$ionic.modalController.create({
          component: SelectSubscriptionModal,
          componentProps: {
            parent: this,
            propsData: {
              onSubmit: (subscription) => {
                modal.dismiss();
                this.confirmBulkChangeSubscription(subscription);
              },
              subscriptions: this.facility.enabled_subscriptions
            }
          }
        });

        return modal.present();
      },
      async bulkUpdateMembers(data) {
        const amount = this.selectionCount;

        try {
          await this.$apollo.mutate({
            mutation: UPDATE_FACILITY_MEMBERS,
            variables: {
              ids: this.selected,
              facility_id: this.id,
              data,
            }
          });
          this.showToast(this.$t('membershipsupdatedamount', {amount}))
        } catch (e) {
          this.error = e;
          return;
        }

        this.refresh();
      },
      async confirmBulkChangeSubscription(subscription) {
        const confirmed = await this.confirm(this.$t('changesubscription'), this.$t('areyousurethatyouwanttochangemanymemberships', {amount: this.selectionCount}));

        if (!confirmed) {
          return;
        }

        return this.bulkUpdateMembers({subscription_id: subscription.id});
      },
      async confirmBulkChangeExpirationDate(date) {
        const confirmed = await this.confirm(this.$t('changeexpirationdate'), this.$t('areyousurethatyouwanttochangemanymemberships', {amount: this.selectionCount}));

        if (!confirmed) {
          return;
        }

        return this.bulkUpdateMembers({expires_at: this.$moment(date).format('YYYY-MM-DD', true)});
      },
    },

    apollo: {
      facility: {
        query: GET_FACILITY,
        variables() {
          return {
            id: this.id
          }
        }
      },
      getFacilityMembers: {
        query: GET_FACILITY_MEMBERS,
        variables() {
          return {
            facility_id: this.id
          }
        },
      },

    }
  }
</script>
