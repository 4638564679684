<template>
  <base-modal :title="$t('choosemembership')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-list>
        <ion-radio-group>
          <ion-list-header>
            {{ $t('memberships') }}
          </ion-list-header>

          <ion-item v-for="subscription in subscriptions" :key="subscription.id" @click="selectSubscription(subscription)">
            <div>
              <ion-label>{{ subscription.name }}</ion-label>
              <ion-badge color="moto">{{ subscription.price | stripeAmount }} / {{ $t('peryear') }}</ion-badge>
            </div>
            <ion-radio slot="end" />
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>

    <ion-footer>
      <ion-button :disabled="!selected" @click="submit" expand="full" color="moto">{{ $t('ok') }}</ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
import BaseModal from './BaseModal.vue'

export default {
  props: ['subscriptions', 'onSubmit'],
  components: {
    BaseModal,
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    async selectSubscription(subscription) {
      this.selected = subscription;
    },
    submit() {
      this.onSubmit(this.selected);
      this.$ionic.modalController.dismiss();
    },
  }
}
</script>